import axios from "axios";
import { AlertError, AlertInfo } from "../components/alert";
import { BASE_URL } from "./constants";

export const Fetch = async (
  url,
  method,
  data,
  options = { ContentType: "application/json" }
) => {
  const token = localStorage.getItem("jwt_token");
  const URL = `${BASE_URL}/${url}`;
  const HEADERS = {
    "Content-Type": options.ContentType,
  };
  if (token) {
    HEADERS.Authorization = `Bearer ${token}`;
  }
  return axios({
    url: URL,
    method,
    headers: HEADERS,
    data,
  });
};

const auth = async () => {
  try {
    //const { data } = await axios({ url: process.env.REACT_APP_BASE_URL_GW_API + '/authentication/Api/v1/Auth', method: 'POST', headers: { 'ApiKey': process.env.REACT_APP_KEY_API } })
    const { data } = await axios({
      url: process.env.REACT_APP_BASE_URL + "/external-auth/auth",
      method: "POST",
    });
    return data;
  } catch (err) {
    console.log(err);
  }
};

const encodeToBase64 = (text) => {
  try {
    let encodedString = btoa(unescape(encodeURIComponent(text)));
    return encodedString;
  } catch (error) {
    console.error("Error al codificar la cadena en Base64:", error);
  }
};

export const FetchApi = async (
  url,
  method,
  data,
  options = { ContentType: "application/json" }
) => {
  let tokenApp = localStorage.getItem("tokenApp");
  let tokenUser = localStorage.getItem("tokenUser");

  try {
    if (url) {
      const axiosApiInstance = axios.create();

      axiosApiInstance.interceptors.request.use(
        async (config) => {
          config.headers = {
            Authorization: "Bearer " + tokenApp,
            "Content-Type": "application/json",
            USERTOKEN: tokenUser,
          };
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      );

      axiosApiInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        async function (error) {
          const originalRequest = error.config;

          if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const { token } = await auth();
            localStorage.setItem("tokenApp", token);
            tokenApp = token;

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + tokenApp;

            return axiosApiInstance(originalRequest);
          }
          return Promise.reject(error);
        }
      );

      //console.log("URL",`${process.env.REACT_APP_BASE_URL_GW_API}/${url}`)
      return await axiosApiInstance({
        url: `${process.env.REACT_APP_BASE_URL_GW_API}/${url}`,
        data,
        method,
      });
    }
  } catch (err) {
    return err;
  }
};

export const FetchApiElastic = async (
  url,
  method,
  data,
  options = { ContentType: "application/json" }
) => {
  let tokenApp = localStorage.getItem("tokenApp");
  let tokenUser = localStorage.getItem("tokenUser");

  try {
    if (url) {
      const axiosApiInstance = axios.create();
      
      const headerHash = encodeToBase64(`${process.env.REACT_APP_USER_SERVER_LOG}:${process.env.REACT_APP_PASSWORD_SERVER_LOG}`);

      axiosApiInstance.interceptors.request.use(
        async (config) => {
          config.headers = {
            // Authorization: "Bearer " + tokenApp,
            "Content-Type": "application/json",
            // USERTOKEN: tokenUser,
            USERTOKEN: `Bearer ${headerHash}`,
          };
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      );

      axiosApiInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        async function (error) {
          const originalRequest = error.config;

          if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const { token } = await auth();
            localStorage.setItem("tokenApp", token);
            tokenApp = token;

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + tokenApp;

            return axiosApiInstance(originalRequest);
          }
          return Promise.reject(error);
        }
      );

      return await axiosApiInstance({
        url: `${process.env.REACT_APP_BASE_URL_API_ELASTIC}/${url}`, data, method
      });
    }
  } catch (err) {
    return err;
  }
};

export const catchError = (error) => {
  console.error("[Error]:", error);
  if (error.response) {
    console.error("[Error Response]:", error.response.data);
    console.error("[Error Response]:", error.response.status);
    if (error.response.status === 403) {
      AlertInfo("No tiene permisos suficientes");
      return;
    }
    if (error.response.status === 404) {
      AlertError("No se encontró lo que buscaba");
      return;
    }
    console.error("[Error Response]:", error.response.headers);
    AlertError(
      "se ha presentado un error" + JSON.stringify(error.response.data)
    );
    return;
  } else if (error.request) {
    console.error("[Error Request]:", error.request);
    console.log(error.request);
    AlertError("se ha presentado un error");
  } else {
    console.log("[Error Message]:", error.message);
    AlertError(error.message);
  }
};

export const fetchLogs = async (req) => {
  const {
    idLogType,
    idLog,
    idCustomer = "",
    description,
    email = ""
				
  } = req;

  const modifiedDescription = `CMS HBO-${description}`;
  const headerHash = encodeToBase64(`${process.env.REACT_APP_USER_SERVER_LOG}:${process.env.REACT_APP_PASSWORD_SERVER_LOG}`);
  const url = `${process.env.REACT_APP_BASE_URL_API_ELASTIC}/api/LogPortal/Create`;

  axios({
    url: url,
																			  
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      USERTOKEN: `Bearer ${headerHash}`,
    },
    data: {
      source: "hb-online",
      idLogType,
      idLog,
      idCustomer,
      description: modifiedDescription,
      email
			 
    },
  });
};
