/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApiElastic } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./logs-estudio.scss";
import { validateModel } from "./validationSchema";
import MonthPickerInput from "react-month-picker-input";
import Pagination from "../../../../utils/Pagination";
import moment from "moment-timezone";

function LogsUsuariosCMS({ isLoadingAction, isLoading }) {
  const currentDate = new Date();
  const defaultMonthYear = `${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/01/${currentDate.getFullYear()}`;

  const [state, setState] = React.useState({
    filter: "",
    random: Math.random(),
    monthYear: defaultMonthYear,
  });
  const [contenido, setContenido] = React.useState(null);
  const [fullContenido, setFullContenido] = React.useState();
  const [topData, setTopData] = React.useState(null);

  const [totalData, setTotalData] = React.useState(0);
  const [isData, setIsData] = React.useState(false);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  const roles = {
    4: "Creador",
    3: "Aprobador",
    6: "Administrador",
    7: "SuperAdministrador",
  };

  // Utilizamos useEffect para establecer el rango inicial
  useEffect(() => {
    const initialDateRange = generateDateRange(selectedYear, selectedMonth);
    setDateRange(initialDateRange);
  }, []); // Se ejecuta solo al montar el componente

  React.useEffect(() => {
    // getContenido();
  }, [state.random]);

  // Estado que me va a servir para la paginación
    const [pagination, setPagination] = useState({
      currentPage: 1, // Página inicial
      totalRecords: 0, // Total de registros (lo actualizas al obtener los datos)
      totalPages: 0, // Total de páginas (lo calculas dinámicamente)
    });
  
    const formatDate = (dateString) => {
      // Parseamos la fecha respetando el offset que viene en el string
      const date = moment.parseZone(dateString);
      
      // Formateamos manteniendo la zona horaria original
      return date.format("DD/MM/YYYY HH:mm:ss");
    };
    
    // Función para obtener el último día del mes
    const getLastDayOfMonth = (year, month) => {
      // El mes en new Date() va de 0-11, y al poner día 0 nos da el último día del mes anterior
      return new Date(year, month, 0).getDate();
    };
  
    // Función para generar el rango de fechas
    const generateDateRange = (year, month) => {
      const lastDay = getLastDayOfMonth(year, month);
      return {
        startDate: formatDate(`${year}/${month}/01`),
        endDate: formatDate(`${year}/${month}/${lastDay}`)
      };
    };

  const getContenido = async (values) => {
    const { page, pageSize } = values;
    isLoadingAction(true);
    try {
      FetchApiElastic(
        `api/LogPortal/getLogsUsuariosCMS`,
        "post",
        {
          source: "hb-online-log-usuario-cms",
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          filter: state.filter,
          page: page || pagination.currentPage
        }
      )
        .then((response) => {
          // Obtenemos el total de registros para crear la paginación
          let total_data = response?.data?.data?.totalCount;
          setTotalData(response?.data?.data?.items.totalCount ?? 0)

          //Formateamos fecha a yyyy/mm/dd
          const formattedData = response?.data?.data?.items.map((item) => ({
            ...item,
            date: formatDate(item.date),
            superAdministrador: item.superAdministrador ?? "", // Si es null, se establece a ""
          })) ?? [];

          setContenido(formattedData);
          
          // Condicional que permite mostrar la paginación al inicializar componente
          if (total_data > 0) setIsData(true); else setIsData(false);

          //Formula: total registros/registros por página
          var totalPagina = Math.ceil(total_data / 10);
        
          setPagination(prev => ({
            ...prev,
            totalPages: totalPagina,
            totalRegistros: total_data
          }));
        })
        .finally(() => {
          isLoadingAction(false);
        });
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function role(data) {
    return roles[data.role];
  }

  function superadministrador(data) {
    return (
      data.superadministrador.nombre + " " + data.superadministrador.apellido
    );
  }

  function fecha(data) {
    return data.created_at.substring(0, 10);
  }

  function filterObject(obj) {
    return Object.values(obj).some((value) => {
      if (typeof value === "object" && value !== null) {
        return filterObject(value);
      } else if (typeof value === "string") {
        return value.toLowerCase().includes(state.filter.toLowerCase());
      }
      return false;
    });
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          {/* CARD DE BUSQUEDAD */}
          <Card>
            <Card.Header>Log usuarios CMS</Card.Header>
            <Card.Body>
              <Formik
                initialValues={state}
                enableReinitialize
                validationSchema={validateModel}
                onSubmit={(values, { setSubmitting }) => {
                  getContenido(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="mb-15">
                      <Col md="3">
                        <span>Mes</span>
                        <MonthPickerInput
                          lang="es"
                          year={new Date().getFullYear()}
                          month={new Date().getMonth()}
                          onChange={function (
                            maskedValue,
                            selectedYear,
                            selectedMonth
                          ) {
                            const newMonth = selectedMonth + 1;

                            // Actualizamos los estados del mes y año
                            setSelectedMonth(newMonth);
                            setSelectedYear(selectedYear);

                            // Calculamos el primer y último día del mes
                            const lastDay = getLastDayOfMonth(selectedYear, newMonth);

                            // Creamos el rango de fechas
                            const newDateRange = {
                              startDate: formatDate(`${selectedYear}/${newMonth}/01`),
                              endDate: formatDate(`${selectedYear}/${newMonth}/${lastDay}`),
                            };

                            // Actualizamos el estado del rango
                            setDateRange(newDateRange);

                            setState({
                              ...state,
                              monthYear:
                                (selectedMonth + 1)
                                  .toString()
                                  .padStart(2, "00") +
                                "/" +
                                "01/" +
                                selectedYear,
                            });
                          }}
                        />
                      </Col>
                      <Col md="4">
                        <span>Descripción</span>
                        <input
                          type="text"
                          className="form-control"
                          name="filter"
                          onChange={(e) => {
                            handleChange(e);
                            setState({
                              ...state,
                              filter: e.target.value, // Set the desired value here
                            });
                          }}
                          value={values?.filter}
                        />
                        <div className="text-danger">{errors?.filter}</div>
                      </Col>
                      <Col md="2" className="d-flex flex-column">
                        <span>&nbsp;</span>
                        <Button onClick={handleSubmit}>
                          <i className="fa fa-search mr-2"></i>Buscar
                        </Button>
                      </Col>
                    </Row>

              <Row>
                <Col md="12">
                  <div className="table-responsive">
                    <DataGridForm
                      dataSource={contenido}
                      keyExpr="role"
                      columns={[
                        { caption: "Apellido", dataField: "apellido" },
                        { caption: "Nombre", dataField: "nombre" },
                        {
                          caption: "Mail",
                          dataField: "email",
                        },
                        {
                          caption: "Rol",
                          render: "role",
                          cellRender: { role },
                        },
                        {
                          caption: "SuperAdministrador",
                          dataField: "superAdministrador",
                        },
                        { caption: "Descripcion", dataField: "descripcion" },
                        { caption: "Fecha", dataField: "date" },
                      
                      ]}
                    />
                  </div>
                </Col>
              </Row>

                  {isData && (
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                      <Pagination
                        currentPage={pagination.currentPage} 
                        totalPages={pagination.totalPages} 
                        onPageChange={(page) => { 
                          setPagination((prev) => ({ ...prev, currentPage: page })); 
                          getContenido({
                            page: page, // Pasa la nueva página seleccionada
                          });
                        }} 
                      />
                    </div>
                  )}
                </form>
              )}
            </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsUsuariosCMS);
