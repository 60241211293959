/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React, { useState } from "react";
import { Card, Col, Row, Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi, FetchApiElastic } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./logs-estudio.scss";
import { validateModel } from "./validationSchema";
import CustomStore from 'devextreme/data/custom_store';


function LogsEstudio({ isLoadingAction, isLoading }) {
  const [state, setState] = React.useState({ filter: "" });
  const [contenido, setContenido] = React.useState(null);

  const [isData, setIsData] = React.useState(false);
  
  // Estado que me va a servir para la paginación
  const [pagination, setPagination] = useState({
    currentPage: 1, // Página inicial
    totalRecords: 0, // Total de registros (lo actualizas al obtener los datos)
    totalPages: 0, // Total de páginas (lo calculas dinámicamente)
  });

  React.useEffect(() => {
    // getContenido(' ');
  }, []);

  const getContenido = async (values) => {
    isLoadingAction(true);

    try {
      setContenido(new CustomStore({
        key: 'index',
        async load(loadOptions) {
          isLoadingAction(true)
          // const resultado = await FetchApi(
          //   `patient/Api/v1/Costumers/GetResultsSentLogs?email=${values.filter}&pageIndex=${ loadOptions.skip / loadOptions.take + 1 || 1}`,
          //   "get"
          // )
          // console.log(resultado)
          // const { data } = resultado.data
          // data.totalCount = data.TotalCount;
          // data.data = data.Logs;

          const resultado = await FetchApiElastic(
                `api/LogPortal/getLogsEstudios`,
                "post",
                {
                  source: "hb-online",
                  email: values.filter,
                  page: `${ loadOptions.skip / loadOptions.take + 1 || 1}`
                }
              )
                .then((response) => {

                  console.log(response.data);
                  
                  // Obtenemos el total de registros para crear la paginación
                  // let total_data = response?.data?.data?.totalCount;
                  // setTotalData(response?.data?.data?.items.totalCount ?? 0)
        
                  //Formateamos fecha a yyyy/mm/dd
                  const formattedData = response?.data?.data?.items.map((item) => ({
                    ...item,
                    date: formatDate(item.date),
                  })) ?? [];
        
                  setContenido(formattedData);
                  
                  // Condicional que permite mostrar la paginación al inicializar componente
                  // if (total_data > 0) setIsData(true); else setIsData(false);
        
                  //Formula: total registros/registros por página
                  // var totalPagina = Math.ceil(total_data / 10);
                
                  // setPagination(prev => ({
                  //   ...prev,
                  //   totalPages: totalPagina,
                  //   totalRegistros: total_data
                  // }));
                })
                .finally(() => {
                  isLoadingAction(false);
                });

                const { data } = resultado?.data?.data?.items;
                data.totalCount = resultado?.data?.data?.totalCount;
                data.data = data;
          isLoadingAction(false)
          return data;
        },
      }))
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  function Validado(data) {
    return (
      <>
        <div>{data.validated ? "SI" : "NO"}</div>
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          {/* CARD DE BUSQUEDAD */}
          <Card>
            <Card.Header>Busqueda de usuarios</Card.Header>
            <Card.Body>
              <Formik
                initialValues={state}
                enableReinitialize
                validationSchema={validateModel}
                onSubmit={(values, { setSubmitting }) => {
                  getContenido(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="mb-15">
                      <Col md="4">
                        <input
                          type="text"
                          placeholder="Buscar email/resultado"
                          class="form-control"
                          name="filter"
                          onChange={handleChange}
                          value={values?.filter}
                        />
                        <div className="text-danger">{errors?.filter}</div>
                      </Col>
                      <Col md="2" className="">
                        <Button onClick={handleSubmit}>
                          <i className="fa fa-search mr-2"></i>Buscar
                        </Button>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>

              <Row>
                <Col md="12">
                  <div className="table-responsive">
                    <DataGridForm
                      dataSource={contenido}
                      keyExpr="email"
                      remoteOperations={true}
                      columns={[
                        { caption: "Email", dataField: "email" },
                        { caption: "Descripción Envío", dataField: "description" },
                        { caption: "Fecha de Envío", dataField: "date" }
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsEstudio);
